var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingDetail.detail,
          expression: "loadingDetail.detail"
        }
      ],
      ref: "appForm",
      attrs: {
        model: _vm.appForm,
        rules: _vm.rules,
        "label-width": "170px",
        "label-suffix": _vm.constants.labelSuffix
      }
    },
    [
      _c(
        "ics-page-inner",
        { attrs: { title: _vm.pageTitle } },
        [
          !_vm.editView()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "企业名称" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(_vm.appForm.companyName)
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          !_vm.editView()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-form-item", { attrs: { label: "统一社会信用代码" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.utils.isEffectiveCommon(_vm.appForm.socialNo)
                        )
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.editView()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "companyName", label: "企业名称" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.companyName,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入企业名称" },
                            model: {
                              value: _vm.appForm.companyName,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "companyName", $$v)
                              },
                              expression: "appForm.companyName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.editView()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "socialNo", label: "统一社会信用代码" } },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.socialNo,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入统一社会信用代码" },
                            model: {
                              value: _vm.appForm.socialNo,
                              callback: function($$v) {
                                _vm.$set(_vm.appForm, "socialNo", $$v)
                              },
                              expression: "appForm.socialNo"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "legalPerson", label: "法定代表人姓名" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.legalPerson,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入法定代表人姓名" },
                        model: {
                          value: _vm.appForm.legalPerson,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "legalPerson", $$v)
                          },
                          expression: "appForm.legalPerson"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "legalIdentity",
                    label: "法定代表人身份证号码"
                  }
                },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.legalIdentity,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入法定代表人身份证号码" },
                        model: {
                          value: _vm.appForm.legalIdentity,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "legalIdentity", $$v)
                          },
                          expression: "appForm.legalIdentity"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "注册资本(元)" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.regAmount,
                        format: function(val) {
                          return _vm.utils.moneyFormat(val, 2)
                        }
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          placeholder: "请输入注册资本"
                        },
                        model: {
                          value: _vm.appForm.regAmount,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "regAmount", $$v)
                          },
                          expression: "appForm.regAmount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "固定电话", prop: "tel" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.tel,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入固定电话" },
                        model: {
                          value: _vm.appForm.tel,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "tel", $$v)
                          },
                          expression: "appForm.tel"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "电子邮箱" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.email,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入电子邮箱" },
                        model: {
                          value: _vm.appForm.email,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "email", $$v)
                          },
                          expression: "appForm.email"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "传真" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.fax,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入传真" },
                        model: {
                          value: _vm.appForm.fax,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "fax", $$v)
                          },
                          expression: "appForm.fax"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "contactName", label: "联系人姓名" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.contactName,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入联系人姓名" },
                        model: {
                          value: _vm.appForm.contactName,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "contactName", $$v)
                          },
                          expression: "appForm.contactName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "contactPhone", label: "联系人电话" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.contactPhone,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入联系人电话" },
                        model: {
                          value: _vm.appForm.contactPhone,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "contactPhone", $$v)
                          },
                          expression: "appForm.contactPhone"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司网站" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.net,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入公司网站" },
                        model: {
                          value: _vm.appForm.net,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "net", $$v)
                          },
                          expression: "appForm.net"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.viewEdit()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "usEnterpriseAddress", label: "注册地址" }
                    },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.usEnterpriseAddress,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("address-picker", {
                            attrs: {
                              "is-show-address-info": _vm.isShowAddressInfo
                            },
                            model: {
                              value: _vm.appForm.usEnterpriseAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appForm,
                                  "usEnterpriseAddress",
                                  $$v
                                )
                              },
                              expression: "appForm.usEnterpriseAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.viewEdit()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "注册地址" } },
                    [
                      _c("address-picker-detail", {
                        model: {
                          value: _vm.appForm.usEnterpriseAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "usEnterpriseAddress", $$v)
                          },
                          expression: "appForm.usEnterpriseAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.viewEdit()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { prop: "dsEnterpriseAddress", label: "办公地址" }
                    },
                    [
                      _c(
                        "ics-item-inner",
                        {
                          attrs: {
                            prop: _vm.appForm.dsEnterpriseAddress,
                            format: _vm.utils.isEffectiveCommon
                          }
                        },
                        [
                          _c("address-picker", {
                            attrs: {
                              "is-show-address-info": _vm.isShowAddressInfo
                            },
                            model: {
                              value: _vm.appForm.dsEnterpriseAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.appForm,
                                  "dsEnterpriseAddress",
                                  $$v
                                )
                              },
                              expression: "appForm.dsEnterpriseAddress"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.viewEdit()
            ? _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "办公地址" } },
                    [
                      _c("address-picker-detail", {
                        model: {
                          value: _vm.appForm.dsEnterpriseAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "dsEnterpriseAddress", $$v)
                          },
                          expression: "appForm.dsEnterpriseAddress"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "经营范围" } },
                [
                  _c(
                    "ics-item-inner",
                    {
                      attrs: {
                        prop: _vm.appForm.scope,
                        format: _vm.utils.isEffectiveCommon
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入经营范围(限300字)",
                          maxlength: 300,
                          type: "textarea"
                        },
                        model: {
                          value: _vm.appForm.scope,
                          callback: function($$v) {
                            _vm.$set(_vm.appForm, "scope", $$v)
                          },
                          expression: "appForm.scope"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.companyType === "capital"
        ? _c(
            "ics-page-inner",
            { attrs: { title: "业务覆盖城市" } },
            [
              _c("ics-city-inner", {
                ref: "city",
                attrs: { "city-info": _vm.appForm.cityList },
                on: {
                  "update:cityInfo": function($event) {
                    return _vm.$set(_vm.appForm, "cityList", $event)
                  },
                  "update:city-info": function($event) {
                    return _vm.$set(_vm.appForm, "cityList", $event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.viewEdit()
        ? _c(
            "el-row",
            { attrs: { gutter: 80 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-bottom": "50px",
                    "padding-top": "30px"
                  },
                  attrs: { span: 24 }
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$router.back()
                        }
                      }
                    },
                    [_vm._v(" 返 回 ")]
                  ),
                  _c(
                    "debounce-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        type: "primary",
                        loading: _vm.loadingDetail.submit
                      },
                      on: { click: _vm.submitForms }
                    },
                    [_vm._v(" 提 交 ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }